import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['check'];

  toggle() {
    $(this.checkTarget).prop('checked', !$(this.checkTarget).prop('checked'));
  }

  stopPropagation(event) {
    event.stopPropagation();
  }
}
