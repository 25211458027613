import { Controller } from '@hotwired/stimulus';
import Cropper from 'cropperjs';

import logoSmall from '@/images/icons/logo-default.svg';
import squareSmall from '@/images/icons/square-logo.svg';
import backgroundSmall from '@/images/icons/background-default.svg';

import 'cropperjs/dist/cropper.css';

const SQUARE_LAYOUT = ['square-small'];
const BACKGROUND_LAYOUT = ['background-small'];
const CIRCLE_LAYOUT = ['circle-small', 'circle-medium'];

const STYLE_LAYOUT = {
  'circle-small': {
    classes: ['rounded-full', 'size-[82px]'],
    src: logoSmall,
  },
  'circle-medium': {
    classes: ['rounded-full', 'size-[148px]'],
    src: logoSmall,
  },
  'square-small': {
    classes: ['rounded-md', 'size-[108px]'],
    src: squareSmall,
  },
  'background-small': {
    classes: ['rounded-md', 'w-[322px]', 'h-[108px]'],
    src: backgroundSmall,
  },
};

export default class extends Controller {
  static targets = ['modal', 'input', 'imageBox', 'replace'];

  connect() {
    this.destroyCropper();
  }

  inputTargetConnected(inputTarget) {
    let options = {};

    const {
      dataset: { styleLayout, files },
    } = inputTarget;

    if (files) {
      options = JSON.parse(files);
    }

    const { attachment_id, source } = options;

    this.initPreview(inputTarget, styleLayout, source);
    this.initRemoveButton(inputTarget, styleLayout, attachment_id);
  }

  initPreview(inputTarget, styleLayout, source) {
    const containerTarget = inputTarget.closest('.cropper-container');

    const label = document.createElement('label');
    const previewElement = document.createElement('img');

    const inputId = inputTarget.getAttribute('id');

    previewElement.classList.add('preview-target');

    label.setAttribute('for', inputId);
    label.classList.add('cursor-pointer', 'overflow-hidden', 'object-cover');

    const layout = STYLE_LAYOUT[styleLayout];

    if (layout) {
      label.classList.add(...layout.classes);
      previewElement.src = source || layout.src;
    }

    label.appendChild(previewElement);
    containerTarget.prepend(label);
  }

  initRemoveButton(inputTarget, styleLayout, attachment_id) {
    const containerTarget = inputTarget.closest('.cropper-container');

    const removeBtn = document.createElement('button');

    removeBtn.classList.add('remove-btn', 'ml-4', 'remove-image-target');
    removeBtn.setAttribute('type', 'button');
    removeBtn.innerHTML = 'Remove';

    if (!attachment_id) removeBtn.setAttribute('disabled', true);

    containerTarget.appendChild(removeBtn);

    removeBtn.addEventListener('click', ({ currentTarget }) => {
      inputTarget.value = '';
      currentTarget.setAttribute('disabled', true);

      const previewElement = containerTarget.querySelector('.preview-target');

      const layout = STYLE_LAYOUT[styleLayout];
      if (layout) previewElement.src = layout.src;

      if (!attachment_id) return;

      const destroyInput = document.createElement('input');
      const inputName = inputTarget.getAttribute('name');
      const baseName = inputName.slice();
      const insertPosition = baseName.lastIndexOf('[');
      const pureInputName = `${baseName.slice(0, insertPosition + 1)}destroyed_${baseName.slice(insertPosition + 1)}[]`;

      document
        .querySelectorAll(`input[name="${pureInputName}"]`)
        .forEach((input) => {
          input.remove();
        });

      destroyInput.setAttribute('type', 'hidden');
      destroyInput.setAttribute('name', pureInputName);
      destroyInput.setAttribute('value', attachment_id);

      containerTarget.appendChild(destroyInput);
    });
  }

  initElement(target, file) {
    this.containerTarget = target.closest('.cropper-container');

    this.inputCurrentTarget = this.containerTarget.querySelector('input');
    this.previewTarget = this.containerTarget.querySelector('.preview-target');
    this.removeTarget = this.containerTarget.querySelector(
      '.remove-image-target',
    );

    this.imageName = file.name;
    this.imageType = file.type;

    const {
      dataset: { styleLayout },
      id: inputId,
    } = this.inputCurrentTarget;

    this.styleLayout = styleLayout;
    this.replaceTarget.setAttribute('for', inputId);
  }

  initCropper(event) {
    this.destroyCropper();

    const file = event.target.files[0];
    const reader = new FileReader();

    this.initElement(event.currentTarget, file);

    reader.onload = (e) => {
      this.imageBoxTarget.src = e.target.result;
      this.modalTarget.showModal();

      this.setUpCropper();
    };

    reader.readAsDataURL(file);
  }

  setUpCropper() {
    let baseOptions = {
      viewMode: 1,
      aspectRatio: 1,
      movable: false,
      zoomable: false,
      zoomOnTouch: false,
      zoomOnWheel: false,
      scalable: false,
      cropBoxResizable: true,
      ready: () => {
        if (CIRCLE_LAYOUT.includes(this.styleLayout)) {
          document
            .querySelector('.cropper-view-box')
            .classList.add('rounded-full');
        }
      },
    };

    if (BACKGROUND_LAYOUT.includes(this.styleLayout)) {
      baseOptions = {
        ...baseOptions,
        aspectRatio: 9 / 3,
      };
    }

    this.cropper = new Cropper(this.imageBoxTarget, baseOptions);
  }

  crop() {
    const croppedCanvas = this.cropper.getCroppedCanvas();

    croppedCanvas.toBlob((blob) => {
      const file = new File([blob], this.imageName, { type: this.imageType });

      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);

      this.inputCurrentTarget.files = dataTransfer.files;

      let src = croppedCanvas.toDataURL();

      if (CIRCLE_LAYOUT.includes(this.styleLayout)) {
        src = this.getRoundedCanvas(croppedCanvas).toDataURL();
      }

      this.previewTarget.src = src;
      this.removeTarget.removeAttribute('disabled');
      this.modalTarget.close();
    });
  }

  destroyCropper() {
    if (!this.cropper) return;

    this.cropper.destroy();
    this.cropper = null;
  }

  close() {
    this.modalTarget.close();
    this.destroyCropper();
  }

  getRoundedCanvas(sourceCanvas) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const width = sourceCanvas.width;
    const height = sourceCanvas.height;

    canvas.width = width;
    canvas.height = height;
    context.imageSmoothingEnabled = true;
    context.drawImage(sourceCanvas, 0, 0, width, height);
    context.globalCompositeOperation = 'destination-in';
    context.beginPath();
    context.arc(
      width / 2,
      height / 2,
      Math.min(width, height) / 2,
      0,
      2 * Math.PI,
      true,
    );

    context.fill();
    return canvas;
  }
}
