import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['modal', 'toggle'];

  closeModalToggle() {
    this.modalTarget.close();
    this.toggleTarget.checked = !this.toggleTarget.checked;
  }
}
