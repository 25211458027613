window.Parsley.addMessages('en', {
  required: 'This is a compulsory field',
});

window.Parsley.addValidator('phone', {
  validateString: (value) => {
    const pattern = /^[1-9]\d{7,14}$/;
    return pattern.test(value);
  },
  messages: {
    en: 'Please enter a valid phone number',
  },
});

window.Parsley.addValidator('greaterorequaltime', {
  validateString: (value, requirement) => {
    return value > requirement;
  },
  messages: {
    en: 'Please enter a time greater than %s.',
  },
});

export const validatesParsley = (elements) => {
  elements.forEach((element) => {
    $(element).parsley().validate();
  });

  return elements.every((element) => $(element).parsley().isValid());
};
