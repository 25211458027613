import { Controller } from '@hotwired/stimulus';
import Pickr from '@simonwep/pickr';
import '@simonwep/pickr/dist/themes/monolith.min.css';

export default class extends Controller {
  static values = { color: String };

  colorTargetConnected(element) {
    const pickr = Pickr.create({
      el: element,
      theme: 'monolith',
      default: this.colorValue || '#00CC66',
      swatches: [
        '#00CC66',
        '#004D26',
        '#009999',
        '#3399FF',
        '#0000FF',
        '#884DFF',
        '#D24DFF',
        '#FF751A',
        '#666699',
        '#FF0000',
        '#FF3333',
        '#FF6666',
        '#FF9999',
        '#FFCCCC',
      ],
      components: {
        preview: true,
        opacity: true,
        hue: true,
        interaction: {
          input: true,
          save: true,
        },
      },
    });

    pickr.on('save', (color) => {
      const rgbaColor = color.toHEXA().toString();
      const colorpickr = $('.colorpickr').find('.radio-value');

      colorpickr.val(rgbaColor);

      $('.radio-color:checked').css({
        border: `2px solid ${rgbaColor}`,
      });

      $('.radio-color:checked:hover').css({
        'border-color': rgbaColor,
      });

      $(`<style>
          .radio-color:checked::before { background-color: ${rgbaColor} !important;}
        </style>`).appendTo('head');

      $('.radio-color:checked:focus').css({
        border: `2px solid ${rgbaColor}`,
      });

      $('.color-picker').hide();
      $('.radio-color').show();
      $('.pcr-app').removeClass('visible');
    });

    $('body').on('click', '.radio-color, .color-picker', () => {
      $('.pcr-button').trigger('click');
    });
  }
}
