import countryFlags from '@/data/country_flags';
import beauty from '@/images/industries/beauty.svg';
import home_services from '@/images/industries/home_services.svg';
import health from '@/images/industries/health.svg';
import fitness from '@/images/industries/fitness.svg';
import education from '@/images/industries/education.svg';
import professional from '@/images/industries/professional.svg';
import automotive from '@/images/industries/automotive.svg';
import pet from '@/images/industries/pet.svg';
import ecommerce from '@/images/industries/ecommerce.svg';

export const countryCodeOptions = (data) => {
  return $(
    `<span class='circle'>${countryFlags[data.id]} ${data.text} </span>`,
  );
};

export const countryCodeSelected = (data) => {
  const country = data.text.split(' ')[0];

  return $(`<span class='circle'>${countryFlags[data.id]} ${country} </span>`);
};

export const countryOptions = (data) => {
  const country = data.text;

  return $(`<span class='circle'>${countryFlags[country]} ${data.id} </span>`);
};

export const currencyOptions = (data) => {
  const country = data.text;

  return $(`<span class='circle'>${countryFlags[country]} ${data.id} </span>`);
};

export const timezoneOptions = (data) => {
  const [countryCode, ...other] = data.text.split(' ');

  return $(
    `<span class='circle w-full'>${countryFlags[countryCode]} <div class='flag-content truncate flex-1'>${other.join(' ')}</div> </span>`,
  );
};

export const industryOptions = (data) => {
  let image = null;

  switch (data.id) {
    case 'Beauty & Wellness':
      image = beauty;
      break;
    case 'Home Services':
      image = home_services;
      break;
    case 'Health & Wellness':
      image = health;
      break;
    case 'Fitness & Personal Training':
      image = fitness;
      break;
    case 'Education & Tutoring':
      image = education;
      break;
    case 'Professional Services':
      image = professional;
      break;

    case 'Automotive Services':
      image = automotive;
      break;

    case 'Pet Services':
      image = pet;
      break;
    case 'Retail & E-commerce':
      image = ecommerce;
      break;
  }

  return $(`<span class='circle w-full'>
              ${image ? `<img src="${image}">` : ''}
              <div class='flag-content truncate flex-1'>${data.id}</div>
            </span>
          `);
};
