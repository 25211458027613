import { Controller } from '@hotwired/stimulus';
import countryFlags from '@/data/country_flags';

export default class extends Controller {
  static targets = ['flag'];

  static values = {
    country: {
      type: String,
      default: '',
    },
  };

  flagTargetConnected(event) {
    const countryFlag = countryFlags[this.countryValue];
    if (countryFlag) {
      $(event).html(`${countryFlag}`);
    }
  }
}
