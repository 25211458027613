import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import { loadStripe } from '@stripe/stripe-js';
import { showFlashMessage } from '../../utils/flash';

export default class extends Controller {
  static targets = [
    'cardNumberError',
    'nameOnCardError',
    'expiryDateError',
    'cvvError',
    'submitBtn',
    'errorModal',
    'errorModalContent',
  ];

  static values = {
    publicKey: String,
    url: String,
    processUrl: String,
  };

  connect() {
    this.cardNumberFilled = false;
    this.cardExpiryFilled = false;
    this.cardCvcFilled = false;
    this.nameOnCardFilled = false;

    this.cardNumberElement = $('#card-number');
    this.nameOnCardElement = $('#name-on-card');
    this.expiryDateElement = $('#expiry-date');
    this.cvcElement = $('#cvc');

    this.StripeElementInvalid = 'StripeElement--invalid';
    this.maxInterval = 5;

    this.initForm();
  }

  async initForm() {
    this.stripe = await loadStripe(this.publicKeyValue);
    this.stripeElements = this.stripe.elements();

    const style = {
      base: {
        fontFamily: 'Helvetica Neue',
        fontSize: '12px',
      },
    };

    this.cardNumber = this.stripeElements.create('cardNumber', {
      style,
      placeholder: 'Enter your card number',
    });

    this.cardExpiry = this.stripeElements.create('cardExpiry', {
      style: style,
      placeholder: 'MM/YY',
    });

    this.cardCvc = this.stripeElements.create('cardCvc', {
      style: style,
      placeholder: 'e.g 123',
    });

    this.cardNumber.mount('#card-number');
    this.cardExpiry.mount('#expiry-date');
    this.cardCvc.mount('#cvc');

    this.cardNumber.on('change', this.handleCardInputChange.bind(this));
    this.cardExpiry.on('change', this.handleCardInputChange.bind(this));
    this.cardCvc.on('change', this.handleCardInputChange.bind(this));

    this.nameOnCardElement.on('input', ({ currentTarget }) => {
      this.nameOnCardFilled = !(
        currentTarget.value === undefined || currentTarget.value === ''
      );

      if (this.nameOnCardFilled) {
        this.nameOnCardErrorTarget.textContent = '';
      }
    });

    this.nameOnCardElement.on('focus', ({ currentTarget }) => {
      $(currentTarget).removeClass(this.StripeElementInvalid);
    });
  }

  async submit(event) {
    event.preventDefault();

    if (this.handleValidateForm()) {
      this.paymentIntent();
    }
  }

  // async createPaymentMethod() {
  //   await this.stripe
  //     .createPaymentMethod({ type: 'card', card: this.cardNumber })
  //     .then((response) => {
  //       const { paymentMethod, errors } = response;

  //       if (errors) {
  //         this.showModalError(errors);
  //       } else {
  //         this.paymentIntent(paymentMethod);
  //       }
  //     });
  // }

  paymentIntent() {
    const csrfToken = $('meta[name="csrf-token"]').prop('content');
    $('#loading').show();

    fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        const {
          errors,
          flash,
          client_secret,
          payment_success_url,
          payment_processing_url,
        } = response;

        if (errors) {
          $('#loading').hide();
          this.showModalError(errors);
        } else {
          this.flash = flash;
          this.payment_success_url = payment_success_url;
          this.payment_processing_url = payment_processing_url;

          this.confirmCardPayment(client_secret);
        }
      })
      .catch(() => {
        $('#loading').hide();
        this.showModalError('Payment failed. Please try again.');
      });
  }

  confirmCardPayment(client_secret) {
    this.stripe
      .confirmCardPayment(client_secret, {
        payment_method: {
          card: this.cardNumber,
          billing_details: {
            name: this.nameOnCardElement.val(),
          },
        },
      })
      .then((response) => {
        if (response.error) {
          $('#loading').hide();
          this.showModalError(response.error.message);
        } else {
          this.count = 0;

          this.setInterval = setInterval(() => {
            this.count += 1;

            this.handleCheckCharge();
          }, 2000);
        }
      });
  }

  handleCheckCharge() {
    const csrfToken = $('meta[name="csrf-token"]').prop('content');

    fetch(this.payment_processing_url, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': csrfToken,
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        const { status } = response;

        if (status == 'success' || status == 'paid') {
          clearInterval(this.setInterval);

          if (this.flash) {
            showFlashMessage(this.flash.type, this.flash);

            setTimeout(() => {
              Turbo.visit(this.payment_success_url);
            }, 3000);
          } else {
            Turbo.visit(this.payment_success_url);
          }
        }

        if (this.count == this.maxInterval && status == 'failed') {
          clearInterval(this.setInterval);
          this.showModalError('Payment failed. Please try again.');
        }

        $('#loading').hide();
      })
      .catch(() => {
        $('#loading').hide();
        clearInterval(this.setInterval);
        this.showModalError('Payment failed. Please try again.');
      });
  }

  handleValidateForm = () => {
    let isValid = true;

    const fieldsToValidate = [
      {
        isFilled: this.cardNumberFilled,
        element: this.cardNumberElement,
        errorTarget: this.cardNumberErrorTarget,
        errorMessage: 'Card number is required.',
      },
      {
        isFilled: this.cardExpiryFilled,
        element: this.expiryDateElement,
        errorTarget: this.expiryDateErrorTarget,
        errorMessage: 'Card expiration date is required.',
      },
      {
        isFilled: this.cardCvcFilled,
        element: this.cvcElement,
        errorTarget: this.cvvErrorTarget,
        errorMessage: 'Card CVC is required.',
      },
      {
        isFilled: this.nameOnCardFilled,
        element: this.nameOnCardElement,
        errorTarget: this.nameOnCardErrorTarget,
        errorMessage: 'Name on Card name is required.',
      },
    ];

    fieldsToValidate.forEach(
      ({ isFilled, element, errorTarget, errorMessage }) => {
        if (!isFilled) {
          element.addClass(this.StripeElementInvalid);
          errorTarget.textContent = errorMessage;
          isValid = false;
        } else {
          element.removeClass(this.StripeElementInvalid);
          errorTarget.textContent = '';
        }
      },
    );

    return isValid;
  };

  handleCardInputChange = (e) => {
    const elementMap = {
      cardNumber: {
        filledProp: 'cardNumberFilled',
        errorTarget: this.cardNumberErrorTarget,
        emptyMessage: 'Card number is required.',
      },
      cardExpiry: {
        filledProp: 'cardExpiryFilled',
        errorTarget: this.expiryDateErrorTarget,
        emptyMessage: 'Card expiration date is required.',
      },
      cardCvc: {
        filledProp: 'cardCvcFilled',
        errorTarget: this.cvvErrorTarget,
        emptyMessage: 'Card CVC is required.',
      },
    };

    const element = elementMap[e.elementType];

    if (element) {
      this[element.filledProp] = !e.empty;

      if (e.error) {
        element.errorTarget.textContent = e.error.message;
      } else if (e.empty) {
        element.errorTarget.textContent = element.emptyMessage;
      } else {
        element.errorTarget.textContent = '';
      }
    }
  };

  showModalError(message) {
    this.errorModalContentTarget.textContent = message;
    this.errorModalTarget.showModal();
  }
}
