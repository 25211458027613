import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form', 'checkbox', 'input'];

  update() {
    const isChecked = this.checkboxTarget.checked;

    this.inputTarget.value = isChecked ? true : false;
  }

  submitForm() {
    this.update();
    const form = this.checkboxTarget.closest('form');
    form.requestSubmit();
  }

  submit(event) {
    event.preventDefault();
    const { action, method } = this.formTarget;
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    fetch(action, {
      method: method,
      body: new FormData(this.formTarget),
      headers: {
        'X-CSRF-Token': csrfToken,
      },
    })
      .then((response) => response)
      .catch((error) => {
        console.error('Form submission error:', error);
      });
  }
}
