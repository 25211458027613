import { Controller } from '@hotwired/stimulus';
import DataTable from 'datatables.net-dt';

export default class extends Controller {
  static targets = ['table', 'input'];

  static values = {
    ajax: { type: String },
  };

  tableTargetConnected(event) {
    $.fn.dataTable.ext.errMode = 'none';

    let options = {
      paging: false,
      searching: true,
      ordering: true,
      info: false,
      dom: 'rt<"bottom"ip><"clear">',
    };

    if (this.ajaxValue) {
      options = {
        ...options,
        processing: true,
        serverSide: true,
        ajax: this.ajaxValue,
      };
    }

    this.table = new DataTable(event, options);

    // this.inputTarget.addEventListener('input', ({ currentTarget }) => {
    //   this.table.search(currentTarget.value).draw();
    // });
  }

  clearSearch() {
    this.inputTarget.value = '';
    this.table.search('').draw();
  }
}
