import { Controller } from '@hotwired/stimulus';
import Cookies from 'js-cookie';

export default class extends Controller {
  toggleSidebar() {
    const sidebarClosed = Cookies.get('sidebarClose') == 'true';
    Cookies.set('sidebarClose', !sidebarClosed);

    const toggle = $('.toggle-sidebar');
    const contentElement = $('.drawer-content');
    const sidebarElement = $('.container-sidebar');
    const sidebarOpenElement = $('.sidebar-open');
    const sidebarCloseElement = $('.sidebar-close');

    sidebarElement.toggleClass('close', !sidebarClosed);
    contentElement.toggleClass('close', !sidebarClosed);
    sidebarOpenElement.toggleClass('!hidden', !sidebarClosed);
    sidebarCloseElement.toggleClass('!hidden', sidebarClosed);
    toggle.toggleClass('before:hidden', !sidebarClosed);
    toggle.toggleClass('after:hidden', sidebarClosed);
  }

  toggleCalendar() {
    const calendarClosed = Cookies.get('calendarClose') == 'true';
    Cookies.set('calendarClose', !calendarClosed);

    const leftSection = $('.left-calendar-section');
    const rightSection = $('.right-calendar-section');
    const calendarOpen = $('.calendar-open');
    const calendarClose = $('.calendar-close');
    const toggle = $('.toggle-calendar');

    leftSection.toggleClass('close', !calendarClosed);
    rightSection.toggleClass('close', !calendarClosed);
    calendarOpen.toggleClass('hidden', !calendarClosed);
    calendarClose.toggleClass('hidden', calendarClosed);
    toggle.toggleClass('before:hidden', !calendarClosed);
    toggle.toggleClass('after:hidden', calendarClosed);
  }
}
