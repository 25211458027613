import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['alert'];

  alertTargetConnected(currentAlert) {
    const alert = $(currentAlert);

    setTimeout(() => {
      alert.fadeOut('slow');
    }, 3000);
  }

  close(event) {
    $(event.currentTarget).closest('.alert').fadeOut('slow');
  }
}
