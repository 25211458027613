import { Controller } from '@hotwired/stimulus';
import {
  countryOptions,
  countryCodeSelected,
  currencyOptions,
  timezoneOptions,
  industryOptions,
  countryCodeOptions,
} from '@/utils/country';

export default class extends Controller {
  static targets = ['input'];

  inputTargetConnected(inputTarget) {
    this.options = {};
    this.buildOptions(inputTarget);

    $(inputTarget).select2(this.options);
  }

  buildOptions(inputTarget) {
    const {
      dataset: { width, placeholder, template, model, noResult },
    } = inputTarget;

    this.options.width = width || '100%';
    this.options.placeholder = placeholder || '';
    this.options.matcher = function (params, data) {
      if ($.trim(params.term) === '') {
        return data;
      }

      const term = params.term.toLowerCase();
      const text = data.text.toLowerCase();
      const id = data.id.toString().toLowerCase();

      if (text.includes(term) || id.includes(term)) {
        return data;
      }

      return null;
    };

    if (model) this.buildModel(model);
    if (template) this.buildTemplate(template);
    if (noResult) this.buildNoResult(noResult);
  }

  buildModel(model) {
    const { type, url } = JSON.parse(model);

    if (type == 'search') {
      this.options.ajax = {
        url: url,
        data: (params) => {
          return {
            search: params.term,
          };
        },
        processResults: function (data) {
          return {
            results: data.results,
          };
        },
      };
    }
  }

  buildTemplate(template) {
    if (template == 'country-code') {
      this.options.templateSelection = countryCodeSelected;
      this.options.templateResult = countryCodeOptions;
      this.options.dropdownCssClass = 'country-code-select2-dropdown';
    }

    if (template == 'country') {
      this.options.templateSelection = countryOptions;
      this.options.templateResult = countryOptions;
    }

    if (template == 'currency') {
      this.options.templateSelection = currencyOptions;
      this.options.templateResult = currencyOptions;
    }

    if (template == 'timezone') {
      this.options.templateSelection = timezoneOptions;
      this.options.templateResult = timezoneOptions;
      this.options.dropdownCssClass = 'timezone-select2-dropdown';
    }

    if (template == 'industry') {
      this.options.templateSelection = industryOptions;
      this.options.templateResult = industryOptions;
      this.options.dropdownCssClass = 'industry-select2-dropdown';
    }
  }

  buildNoResult(noResult) {
    const { title, message, url, url_content } = JSON.parse(noResult);

    this.options.escapeMarkup = (markup) => markup;
    this.options.language = {
      noResults: () => {
        return `
          <div class="no-results-container">
            <p class="no-results-message">${title}</p>
            <p class="no-results-subtext">${message}</p>
            <a href="${url}" class="add-link" target="_blank", rel="noopener">${url_content}</a>
          </div>
        `;
      },
    };
  }
}
