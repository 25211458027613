import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
export default class extends Controller {
  static targets = ['input'];

  static values = {
    param: {
      type: String,
      default: '',
    },
  };

  connect() {
    this.element.addEventListener('keypress', this.preventEnterSubmit);
    this.closeSearch();
  }

  disconnect() {
    this.element.removeEventListener('keypress', this.preventEnterSubmit);
  }

  preventEnterSubmit(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  update(event) {
    this.performSearch(event.target.value, event.target.dataset.searchUrl);
  }

  performSearch(query, url) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    fetch(`${url}?${this.paramValue}=${query}`, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': csrfToken,
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      });
  }

  updateForm(event) {
    this.performSearchForm(event.target.value);
  }

  performSearchForm(query) {
    const url = new URL(this.element.action);
    url.searchParams.set(this.paramValue, query);

    fetch(url)
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      });
  }

  clearSearch() {
    this.inputTarget.value = '';
    this.performSearchForm('');
  }

  closeSearch() {
    $('body').on('click', '.close-search', ({ currentTarget }) => {
      const searchContainer = currentTarget.closest('.search-container');
      const input = searchContainer.querySelector('.search-input');

      input.value = '';
      this.performSearch('', input.dataset.searchUrl);
    });
  }
}
