import { Controller } from '@hotwired/stimulus';
import flatpickr from 'flatpickr';
import moment from 'moment';
import 'flatpickr/dist/flatpickr.css';

export default class extends Controller {
  static targets = ['datepicker', 'timepicker'];

  datepickerTargetConnected(element) {
    const {
      dataset: { mode, startDate, endDate, dateFormat },
    } = element;

    let option = {
      altInput: true,
      altFormat: dateFormat || 'd/m/Y',
      dateFormat: dateFormat || 'd/m/Y',
      monthSelectorType: 'static',
      allowInput: true,
      disableMobile: true,
    };

    if (mode) {
      this.mode = mode;
      let start = new Date(startDate);
      let end = new Date(endDate);

      option = {
        ...option,
        mode,
        locale: {
          rangeSeparator: ' - ',
        },
        defaultDate: [start, end],
      };
    }

    this.datepickr = flatpickr(element, option);
  }

  timepickerTargetConnected(element) {
    flatpickr(element, {
      enableTime: true,
      noCalendar: true,
      dateFormat: 'H:i',
      time_24hr: true,
      disableMobile: true,
    });
  }
}
