import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  static targets = ['submit', 'notes', 'form', 'buttonGroup'];

  static values = {
    data: String,
  };

  editNote() {
    this.showButtonGroup();
    this.enableNote();
  }

  deleteNote() {
    this.showButtonGroup();
    this.clearNote();
    this.enableNote();
  }

  cancelNote() {
    this.hideButtonGroup();
    this.resetNote();
    this.disableNote();
  }

  enableNote() {
    this.notesTarget.disabled = false;
    this.notesTarget.focus();
  }

  disableNote() {
    this.notesTarget.disabled = true;
  }

  clearNote() {
    this.notesTarget.value = '';
  }

  resetNote() {
    this.notesTarget.value = this.dataValue;
  }

  showButtonGroup() {
    this.buttonGroupTarget.classList.remove('hidden');
  }

  hideButtonGroup() {
    this.buttonGroupTarget.classList.add('hidden');
  }

  submitNote() {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    const formData = new FormData(this.formTarget);
    formData.append('note', this.notesTarget.value);

    fetch(this.formTarget.action, {
      method: 'PUT',
      body: formData,
      headers: { 'X-CSRF-Token': csrfToken },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      });
  }
}
